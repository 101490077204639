.nav_bar {

  width: 100%;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  align-items: center;
  position: sticky;
  top: 0px;

  /* Layout Properties */

  top: 0px;
  left: 0px;
  height: 90px;

  /* UI Properties */

  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2828284C;
  opacity: 1;
  .logo_c {
    width: 50%;
    min-width: 100px;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
  h1{
    font-family: 'Odibee Sans', cursive;
    margin-top:10px;
  }
    .logo {
      width: 100px;
      margin-left: 40px;
    }
    a {
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
  .login {
    width: 50%;
    min-width: 100px;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    small {
      display: none;
    }
  }
}

#toggleContainer {
  align-items: center;
  display: flex;
  .clickOut {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  #dashboard {
    position: absolute;
    top: 50px;
    //top: 45px;
    //left: -5px;
    z-index: 10;
    display: flex;
    //display: none;
    flex-direction: column;
    width: 120px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 0  0 5px 0;
    align-items: center;
    justify-content: center;
    background-color: #d3d4ff;
    opacity: 0.9;
  }
  #toggle_button {
    position: absolute;
    top: 0px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    color: black;
  }
  #toggle_button:hover {background-color: rgba(211, 212, 255, 0.42);}
  #carrito_button {
    position: absolute;
    top: 0px;
    left: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    color: black;
  }
  #carrito_button:hover {background-color: rgba(211, 212, 255, 0.42);}
}

#fact_display {
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 10;
  width: 10%;
  #fact_container {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    box-sizing: border-box;
    border-radius: 0  0 5px 0;
    align-items: center;
    justify-content: center;
    background-color: #d3d4ff;
    opacity: 0.9;
    p {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 600px) {
  .nav_bar .logo_c h1 {
    font-size: 2.5rem;
  }
}
