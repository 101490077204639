@import './header';
@import './sessions';
@import './games';

/*colors*/
$white: #FFFFFF;
$milk: #FFFEF8;
$black: #323232;
$gray: #909090;
$light_purple: #A7AAFE;
$purple: #595EFF;



* {
  margin: 0;
  padding: 0;
}

html {
  text-align: center;
  height: 100%;
  width: 100%;
  body {
    background: $milk;
    //background-image: url("https://www.transparenttextures.com/patterns/mirrored-squares.png");
    color: $black;
    //font-family: 'Montserrat', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 100%;
    main {
      z-index: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin: auto 0px;
      margin-top:30px;
    }
  }
}

@media screen and (min-width: 900px) {
  html {
    body {
      header {
        .nav_bar {
          .login {
            small {
              display: inline;
            }
          }
        }
      }
    }
  }
}

h1, h2, p {
  text-align: center;
  margin: 4px;
}



.form {
  box-sizing: border-box;
  background: $white;
  box-shadow: 0px 3px 6px #2828284C;
  border-radius: 20px;
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 200px;
  padding: 20px 10px 30px 10px;
  .field {
    padding: 15px 15px;
    text-align: center;
    align-content: center;
  }
  .errors {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    p {
      font-weight: bold;
    }
    ul {
      width: 100%;
      margin: 8px 25px;
      list-style-position: inside;
    }
  }
}

.button_panel {
  display: grid;
  align-items: center;
  margin: 10px 25px 10px 10px;
}

.flex_sides {
  display: flex;
  justify-items: space-between;
}

/*
button, input[type=submit] {
  background-color: #d3d4ff;
  border: 1px solid black;
  opacity: 1;
  border-radius: 5px;
  font-size: 12px;
  display: block;
  width: fit-content;
  margin: 5px;
  padding: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.18), 0 4px 4px 0 rgba(0,0,0,0.15);
  cursor: pointer;
}

 */

.link {
  text-decoration: none;
}

table.dataTable{
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2828284C;
  border-radius: 20px;
  opacity: 1;
  margin-top: 30px !important;

  td,th{
    border-width: 0.01em;
    border-bottom: 0;
  }


}

.table > :not(:last-child) > :last-child > * {
  border-bottom: 0;
  text-align: center;
  color: $purple;
  font-weight: unset;
}

.dataTables_wrapper .dataTables_length select{
  background: whitesmoke;
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate,.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active,.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
  color: $light_purple !important;
  background: none !important;
  border: none !important;
}

.dataTables_info,.dataTables_paginate.paging_simple_numbers{
  font-size: 12px !important;
}
#game-list_length,#game-list_filter input{
  margin-bottom: 25px;
  margin-top: 20px;
}

#game-list_filter input{
  border: 1px solid $gray !important;
  border-radius: 10px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 50px;
  i {
    margin: 3px;
  }
}
a, a button{
  color:ghostwhite !important;
}
a:hover{
  color:whitesmoke;
}


.scene{margin: 20px; margin-bottom: 50px; padding: 10px;}

.scenes .scene:nth-child(odd) {
  background: #ececec;
}

.scenes .scene:nth-child(even) {
  background: rgba(211, 212, 255, 0.42);
}


.btn{
  top: 508px;
  left: 985px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 37px;
  text-transform: uppercase;
  font-size: 15px;
  margin: 10px 0 10px 0;
}

.bg-light-purple{
  background: $light_purple;
}

.bg-purple{
  background: $purple;
}

.bg-gray{
  background: $gray;
}

.section-box{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: auto 0px;
  margin-top:30px;
  /* Layout Properties */

  top: 10px;
  left: 20%;
  padding:20px 25% 20px 25%;

  /* UI Properties */

  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px #2828284D;
  border-radius: 20px;
  opacity: 1;
}

.section-box h1,.container h1{
  font-size: 25px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.purple-link a{
  font-size: 20px;
  text-align: left;
  color: $purple !important;
  line-height: 50px;
}

input{
  border: 2px solid $black;
  border-radius: 37px;
  opacity: 1;
  padding: 5px;
  margin-top: 10px;
  width: 300px;
}

input[type=submit]{
  color: $white
}
