
/*colors*/
$white: #FFFFFF;
$milk: #FFFEF8;
$black: #323232;
$gray: #909090;
$light_purple: #A7AAFE;
$purple: #595EFF;


.fa-ghost{
  color: $white;
}

.btn-grad {
  background: $purple;
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.8s;
  background-size: 200% auto;
  color: $white;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  opacity: 1;
}

.alpaca-form-button.alpaca-form-button-submit.btn.btn-default,.alpaca-array-actionbar-action.btn.btn-default {
  background: $light-purple;
  color: black;
  position: initial !important;
  height: 30px;
}

#game-list img{
  height: 20px;
  margin-left: 10px;

}


//new

.game-description{
  display: block;
}

.game-epi,.game-title,.game-lead {
  text-align: left;
  line-height: 28px;
}

.game-title{
  font-size: 28px;
  font-family: Montserrat;
}

.game-lead{
  font-size: 14px;
  font-style: italic;
  font-weight: lighter;
}

.game-style{
  color: $purple;
  display: inline-flex;
}

a > .game-config{
  float: right;
  width: 20%;
  margin-bottom: 20px;
}

#rounds{
  background: $white;
  box-shadow: 0px 4px 10px #2828284D;
  border-radius: 20px;
  padding: 25px;

  .game-description{
    margin-bottom: 20px;
  }

  .game{
    border: 3px solid $gray;
    color: $gray;
    border: 0.5px solid var(--unnamed-color-909090);
    background: $white 0% 0% no-repeat padding-box;
    border: 0.5px solid #909090;
    border-radius: 20px;
    margin: 10px 35px 10px 35px;
  }

  .card-header{
    background-color: $light_purple;
    border-top: none;
    border-bottom: none;
    color: $white;
    display: flow-root;
    padding: 25px;
    border-radius: 20px;
  }

  .card-text{
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
    position: relative;
    font-weight: lighter;
  }

  .fa-chess-board{
    float: left;
    margin-left: 20%;
  }

  .game h5{
    font-size: 27px;
    float: left;
    width: 100%;
    display: flex;
    margin-left: 10px;
  }

  .card-footer{
    border-top: none;
    border-bottom: none;
    width: 100%;
    background: none;
    display: block;

    .link{
      position: relative;
      display: block;
      width: 20px;
      float: inline-end;
      margin-right: 10px;
    }
  }

  .add{
    background: none;
    border: none;
    float: right;
  }

  .add > img{
    width: 50px;
    float: inline-end;
    margin-top: 20px;
  }

}

.btn-primary{
  background: $light_purple !important;
  border:none !important;
  border-radius: 37px !important;
  min-width: 150px;
}

.btn-primary:hover{
  background: $purple !important;
}

.btn-purple{
  background: $purple !important;
  border:none !important;
  border-radius: 37px !important;
}

#badge{
  float: inline-start;
  color: $light_purple;
}

input{
  border-radius: 100px !important;
}

.game-sections{
  color: $gray;
  background: $white;
  margin-top: 20px;

  .card-header,.card-footer{
    background: white;
    border: none;
    color: $light-purple;
  }
}

div[data-alpaca-container-item-parent-field-id="alpaca1"] {
  background: white;
  margin: 20px 0 40px 0;
  box-shadow: 0px 4px 10px #2828284D;
  border-radius: 20px;
  min-height: 80px;
  padding: 10px;
}

.checkbox input[type="checkbox"]{
  position: relative !important;
}

table.eduju{
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2828284C;
  border-radius: 20px;
  min-width: 300px;
  display: block;
  padding: 50px;
  display: table;
  margin-top: 30px;
  color: $gray;
  border-collapse:collapse;border-spacing:0;margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align:left;vertical-align:top;

  td{
    padding: 20px;
    border-width: 0.01em;
    border-bottom-width: 0.01em;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-color: $gray !important;
    border-radius: 20px;
    vertical-align: middle;
    font-size:14px;
    overflow:hidden;
    word-break:normal;
  }

  th{
    color:$black !important;font-size:16px;
    font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;text-align:center;
  }

  .btn{
    height: 40px;
    min-width: auto !important;
    width: 120px !important;
    font-size: 12px !important;
  }

}

