.login_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 320px;
  max-height: 200px;
  padding: 10px;
  border: 5px solid #FFB793;
  margin: auto;
  // flex: 1;
  h1 {
    flex: 1;
  }
  .button_panel {
    align-items: flex-start;
    flex: 3;
  }
  button {
    flex: 0;
  }
}

.credentials_c {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: auto;
  .form {
    min-width: 400px;
  }
}